import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import DOMPurify from 'dompurify';
import debounce from 'lodash.debounce';
import './Welcome.css';

const apiUrl = process.env.REACT_APP_API_URL || 'https://autotrade.futurerichguy.com';

const Welcome = () => {
  const [fileContent, setFileContent] = useState('');
  const [toggleOne, setToggleOne] = useState(false);
  const [toggleTwo, setToggleTwo] = useState(false);
  const [picklistOne, setPicklistOne] = useState(1); // State for the first picklist
  const [picklistTwo, setPicklistTwo] = useState(1); // State for the second picklist
  const { username, logout } = useAuth();
  const [isDisabled, setIsDisabled] = useState(false);

  const isWithinTimeRange = useCallback(() => {
    const now = new Date();
    const start = new Date();
    const end = new Date();
    start.setHours(9, 30, 0);  // 9:30 AM
    end.setHours(16, 1, 0);    // 4:00 PM
    return now >= start && now <= end;
  }, []);

  useEffect(() => {
    setIsDisabled(isWithinTimeRange());
    const interval = setInterval(() => {
      setIsDisabled(isWithinTimeRange());
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [isWithinTimeRange]);

  useEffect(() => {
    const handleLogoutOnClose = () => {
      logout(); // Call the logout function when the browser is closed
    };

    window.addEventListener('beforeunload', handleLogoutOnClose);

    return () => {
      window.removeEventListener('beforeunload', handleLogoutOnClose);
    };
  }, [logout]);

  const fetchFileContent = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/get_file_content`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        logout();
        throw new Error('Force to logout');
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const sanitizedContent = DOMPurify.sanitize(data.update.replace(/\n/g, '<br />'));
      setFileContent(sanitizedContent);
      console.log('Fetched data:', sanitizedContent);
    } catch (error) {
      console.error('Error fetching file content:', error);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/gettoggle`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      setToggleOne(data.toggleOne);
      setToggleTwo(data.toggleTwo);
      setPicklistOne(data.ratio);
      setPicklistTwo(data.scalp_ratio);
      console.log('Fetched toggle states:', data);
    } catch (error) {
      console.error('Error fetching toggle states:', error.message);
    }
  };

  const updateToggleState = async (toggleName, value) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/settoggle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ toggleName, value }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update toggle: ${response.statusText}`);
      }

      console.log(`Updated ${toggleName} to ${value}`);
    } catch (error) {
      console.error('Error updating toggle state:', error.message);
    }
  };

  const debouncedUpdateToggleState = debounce(updateToggleState, 300);

  const handleToggleOneChange = () => {
    const newValue = !toggleOne;
    setToggleOne(newValue);
    debouncedUpdateToggleState('toggleOne', newValue);
  };

  const handleToggleTwoChange = () => {
    const newValue = !toggleTwo;
    setToggleTwo(newValue);
    debouncedUpdateToggleState('toggleTwo', newValue);
  };

  const handlePicklistOneChange = (e) => {
    setPicklistOne(e.target.value);
    debouncedUpdateToggleState('picklistOne', e.target.value);
    console.log(`Jarvis 3.0 ratio selected: ${e.target.value}`);
  };

  const handlePicklistTwoChange = (e) => {
    setPicklistTwo(e.target.value);
    debouncedUpdateToggleState('picklistTwo', e.target.value);
    console.log(`Jarvis scalp ratio selected: ${e.target.value}`);
  };

  useEffect(() => {
    fetchFileContent();
    fetchStates();
    const interval = setInterval(fetchFileContent, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="welcome-container">
      <h1 className="welcome-title">Welcome to Futurerich Academy, {username}!</h1>
      <div className="toggles-container">
        <div className="toggle">
          <span>Jarvis 3.0 </span>
          <input
            id="toggleOne"
            type="checkbox"
            checked={toggleOne}
            onChange={handleToggleOneChange}
          />
          <label htmlFor="toggleOne" className="toggle-switch"></label>
          {toggleOne && (
            <>
              <label htmlFor="picklistOne" className="picklist-label">Jarvis 3.0 Ratio: </label>
              <select
                id="picklistOne"
                value={picklistOne}
                onChange={handlePicklistOneChange}
                className="custom-select"
                disabled={isDisabled} // Disable based on time
                style={{ backgroundColor: isDisabled ? '#e0e0e0' : 'white' }} // Grey out
              >
                {[...Array(10).keys()].map(i => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className="toggle">
          <span>Jarvis Scalp </span>
          <input
            id="toggleTwo"
            type="checkbox"
            checked={toggleTwo}
            onChange={handleToggleTwoChange}
          />
          <label htmlFor="toggleTwo" className="toggle-switch"></label>
          {toggleTwo && (
            <>
          <label htmlFor="picklistTwo" className="picklist-label">Jarvis Scalp Ratio: </label>
          <select
            id="picklistTwo"
            value={picklistTwo}
            onChange={handlePicklistTwoChange}
            className="custom-select"
            disabled={isDisabled} // Disable based on time
            style={{ backgroundColor: isDisabled ? '#e0e0e0' : 'white' }} // Grey out
          >
            {[...Array(10).keys()].map(i => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          </>
          )}
        </div>
      </div>
      <div className="file-content" dangerouslySetInnerHTML={{ __html: fileContent }} />
      <button className="logout-button" onClick={logout}>Logout</button>
    </div>
  );
};

export default Welcome;
