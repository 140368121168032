import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Login.css';

const apiUrl = process.env.REACT_APP_API_URL || 'https://autotrade.futurerichguy.com';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mfacode, setMfaCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear previous errors
    try {
      const response = await fetch(`${apiUrl}/loginb`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( { email, password, mfacode }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        login(data.access_token, data.refresh_token, data.username); // Update authentication state
        navigate('/welcome'); // Redirect to the welcome page
      } else {
        setError(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      setError('Failed to fetch');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1 className="login-title">Futurerich Academy</h1>
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Login to Robinhood</h2>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mfacode">MFA Code(Google Auth Only):</label>
            <input
              type="text"
              id="mfacode"
              value={mfacode}
              onChange={(e) => setMfaCode(e.target.value)}
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
